import React, { FC } from "react";
import { Route } from "../../context/Router/models";
import styles from "./styles.module.scss";

const Main: FC = () => (
  <div className={styles.placeholder}>
    <img alt="" src="/logo512.png" />
    <p>
      inquiries{" - "}
      <a href="mailto:randy.little@parheliongames.com">
        randy.little@parheliongames.com
      </a>
    </p>
  </div>
);

export default {
  name: "",
  component: Main,
} as Route;
